import { useIntl } from "gatsby-plugin-intl"
import React from 'react'
import { Row } from "react-bootstrap"
import { Verdict } from "../types/Verdict"

const VerdictInfo = ({ verdict }: { verdict: Partial<Verdict> }): JSX.Element => {
  const intl = useIntl()

  return (
    <>
      {verdict ? (
        <>
          <div className="verdict-card p-4 mt-4">
            <Row className="d-flex align-items-center justify-content-between mb-4 mx-auto w-100">
              <div className="circle green" />
              <span className="rating-info">
                {intl.formatMessage({ id: 'agency.disponibility' })}
              </span>
              <span className="rating-value">{verdict.disponibility}</span>
            </Row>
            <Row className="d-flex align-items-center justify-content-between mb-4 mx-auto w-100">
              <div className="circle red" />
              <span className="rating-info">
                {intl.formatMessage({ id: 'agency.declaration' })}
              </span>
              <span className="rating-value">{verdict.declaration}</span>
            </Row>
            <Row className="d-flex align-items-center justify-content-between mb-4 mx-auto  w-100">
              <div className="circle blue" />
              <span className="rating-info">
                {intl.formatMessage({ id: 'agency.norms' })}
              </span>
              <span className="rating-value">{verdict.norms}</span>
            </Row>
            <Row className="d-flex align-items-center justify-content-between mb-4 mx-auto w-100">
              <div className="circle yellow" />
              <span className="rating-info">{intl.formatMessage({ id: 'agency.quality' })}</span>
              <span className="rating-value">{verdict.quality}</span>
            </Row>
            <Row className="d-flex align-items-center justify-content-between mx-auto w-100">
              <div className="circle purple" />
              <span className="rating-info">
                {intl.formatMessage({ id: 'agency.usability' })}
              </span>
              <span className="rating-value">{verdict.usability}</span>
            </Row>
          </div>
          <div className="d-flex justify-content-between align-items-center p-4 verdict-total-container">
            <span className="verdict_total">Total</span>
            <span className="">{verdict.total}</span>
          </div>
        </>
      ) : null}
    </>
  )
}

export default VerdictInfo
