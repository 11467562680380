import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Agency from "../components/media-agency/media-agency"

const MediaAgencyPage = ({ location }): JSX.Element => {
  const parts = location.pathname.split('/')
  const lastPath = parts.pop() || parts.pop()

  return (
    <Layout>
      <Router basepath="/media-agency"></Router>
      <Agency activePath={lastPath.toLowerCase()} path={`/${lastPath.toLowerCase()}`} />
    </Layout>
  )
}

export default MediaAgencyPage

