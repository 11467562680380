import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Row, Col } from 'react-bootstrap'

const AgencySkeleton = (): JSX.Element => {
  return (
    <Row className="my-5">
      <Col lg={4} md={6} xs={12} className="px-4">
        <Skeleton height={600} count={1} />
      </Col>
      <Col lg={8} md={6} xs={12} className="px-4 d-flex flex-column justify-content-between">
        <Skeleton height={180} count={1} />
        <Skeleton height={180} count={1} />
        <Skeleton height={180} count={1} />
      </Col>
    </Row>
  )
}

export default AgencySkeleton
