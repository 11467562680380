import React from 'react'
import ActivityRings, { ActivityRingsConfig, ActivityRingsData } from "react-activity-rings"
import { Verdict } from '../../types/Verdict'
import { Row } from 'react-bootstrap'

const RingsChart = ({ verdict }: { verdict: Partial<Verdict> }): JSX.Element => {

  const data: ActivityRingsData = [
    {
      label: "Disponibility",
      value: verdict.disponibility / 5,
      color: "#00CC82",
      backgroundColor: "#d0dbfb"
    },
    {
      label: "Declaration",
      value: verdict.declaration / 5,
      color: "#FF6082",
      backgroundColor: "#d0dbfb"
    },
    {
      label: "Norms",
      value: verdict.norms / 5,
      color: "#6ABAFF",
      backgroundColor: "#d0dbfb"
    },
    {
      label: "Quality",
      value: (verdict.quality + 36) / 72,
      color: "#FDCA00",
      backgroundColor: "#d0dbfb"
    },
    {
      label: "Usability",
      value: verdict.usability / 5,
      color: "#8645FF",
      backgroundColor: "#d0dbfb"
    }
  ]

  const config: ActivityRingsConfig = {
    width: 250,
    height: 250
  }

  return (
    <Row className="my-5 justify-content-center">
      <ActivityRings
        data={data}
        config={config}
      />
    </Row>
  )
}

export default RingsChart