import React, { useState, useEffect } from "react"
import * as R from "ramda"
import { useQuery } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import { useIntl } from "gatsby-plugin-intl"
import { Col, Container, Row } from "react-bootstrap"
import AgencyCard from "../home/agency-card"
import Separator from "../separator"
import ShowMore from "../show-more"
import VerdictInfo from "../verdict-info"
import AgencyChart from "./agency-chart"
import AgencySkeleton from "./agency-skeleton"
import NotificationsForm from "./notifications-form"
import RingsChart from "./ring-chart"
import SEO from "../seo"

const Agency = ({ activePath, ...rest }): JSX.Element => {
  const intl = useIntl()
  const [postFix, setPostfix] = useState(intl.locale === 'ru' ? '_ru' : '')

  const { loading, error, data } = useQuery(MEDIA_AGENCY, {
    variables: { agencySlug: activePath },
  })

  const agency = R.pathOr(null, ['allMediaAgencies', 0], data)
  useEffect(() => {
    setPostfix(intl.locale === 'ru' ? '_ru' : '')
  }, [intl.locale])

  const renderError = error ? <div>{intl.formatMessage({ id: 'general.errorLoading' })}</div> : <></>
  const renderSkeleton = !error && loading ? <AgencySkeleton /> : <></>

  return (
    <>
      {!agency && <SEO title={intl.formatMessage({ id: 'home.subtitle' })} />}
      {agency && <SEO title={agency.name} description={agency[`description${postFix}`] || agency.description} />}
      <Container className="pt-lg-5 pt-md-4 pt-0 pb-5">
        {renderError}
        {renderSkeleton}
        <Row className="mt-lg-5 mt-0">
          <Col
            lg={4}
            md={6}
            xs={12}
            className="bg-white px-4 charts-column mb-lg-0 mb-3"
          >
            {agency && <AgencyCard agency={agency} separator={false} externalLink={true} credibilityBadge={false} />}
            {agency && <NotificationsForm tag={agency.id} />}
            <Separator />
            {R.pathOr(null, ["verdicts", 0], agency) && (
              <VerdictInfo verdict={R.pathOr(null, ["verdicts", 0], agency)} />
            )}
            {R.pathOr(null, ["verdicts", 0], agency) && (
              <RingsChart verdict={R.pathOr(null, ["verdicts", 0], agency)} />
            )}
          </Col>
          <Col
            lg={8}
            md={6}
            xs={12}
            className="px-lg-4 px-0 d-flex flex-column"
          >
            {R.pathOr("", ["verdicts", [0], "notes"], agency) && (
              <div id="showMoreVerdict" className="mb-3">
                <ShowMore
                  content={agency.verdicts[0][`notes${postFix}`] || agency.verdicts[0].notes}
                  title={intl.formatMessage({ id: 'agency.verdict' })}
                  isLongContent={false}
                  className="verdict-content"
                  isCollapsed={true}
                />
              </div>
            )}
            
            {/* {R.pathOr([], ['verdicts'], agency).length ?
              <div className="px-4 bg-white mb-3 line-chart-container">
                <p className="show_more_text_title">{intl.formatMessage({ id: 'agency.evolution' })}</p>
                <AgencyChart verdicts={agency.verdicts} name={agency.name} />
              </div> 
              : null} */}
              
            {R.pathOr("", ["description"], agency) && (
              <div id="showMoreAbout">
                <ShowMore
                  content={agency[`description${postFix}`] || agency.description}
                  title={intl.formatMessage({ id: 'agency.description' })}
                  isLongContent={false}
                  className="about-content"
                  isCollapsed={true}
                />
              </div>
            )}
          </Col>
        </Row>
      </Container >
    </>
  )
}

const MEDIA_AGENCY = gql`
 query allMediaAgencies($agencySlug: String!) {
 allMediaAgencies(where: { path: $agencySlug }, first: 1) {
    id
    name
    url
    # description
    # description_ru
    path
    photo {
      publicUrl
    }
    icon {
      publicUrl
    }
    verdicts (sortBy: verdictDate_DESC) {
      id
      createdAt
      verdictDate
      disponibility
      declaration
      norms
      quality
      usability
      total
      notes
      notes_ru
      credibilityLabel
    }
  }
}
`
export default Agency
