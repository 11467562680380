import { useIntl } from "gatsby-plugin-intl"
import parse from 'html-react-parser'
import React, { useState } from 'react'

const ShowMore = ({ content, title, isLongContent, className, isCollapsed = false }) => {
  const [collapsed, setCollapsed] = useState(false || isCollapsed)
  const intl = useIntl()

  return (
    <div className="px-4 bg-white show-more-container">
      <p className="show-more-text-title">{title}</p>
      <div
        className={className}
        style={{ overflow: "hidden", maxHeight: collapsed ? null : "4.5rem" }}
      >
        <section>
          {parse(content)}
        </section>
      </div>
      {isLongContent && <button
        className="show-button d-flex justify-content-center align-items-center mt-4"
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? intl.formatMessage({ id: 'agency.showLess' }) : intl.formatMessage({ id: 'agency.showMore' })}
      </button>
      }
    </div>
  )
}

export default ShowMore
