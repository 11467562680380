import React, { useEffect, useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import firebase from "gatsby-plugin-firebase"
import FontAwesome from "react-fontawesome"
import { useIntl } from "gatsby-plugin-intl"

type NotificationsFormProps = {
  tag: string
}

const NotificationsForm: React.FC<NotificationsFormProps> = ({ tag }) => {
  const intl = useIntl()
  const [permission, setPermission] = useState("default")
  const [fcmToken, setFcmToken] = useState("")
  const [getTopics, { data: topicsData }] = useLazyQuery(FCM_TOPICS)
  const [subscribe] = useMutation(FCM_SUBSCRIBE)

  useEffect(() => {
    const init = async () => {
      if (Notification.permission === "granted") {
        const token = await firebase.messaging().getToken()
        setFcmToken(token)
      } else if (Notification.permission === "denied") {
        setPermission("denied")
      }
    }
    init()
  }, [])

  useEffect(() => {
    const getData = async () => {
      if (fcmToken) {
        await getTopics({ variables: { token: fcmToken } })
      }
    }
    getData()
  }, [fcmToken])

  useEffect(() => {
    if (topicsData?.getTopics?.topics?.includes(tag)) {
      setPermission("subscribed")
    }
  }, [topicsData?.getTopics?.topics?.length, tag])

  const onSubscriptionBtnClick = React.useCallback(async () => {
    const token = await firebase.messaging().getToken()
    if (token) {
      setPermission("subscribed")
      await subscribe({
        variables: {
          token,
          topic: tag,
          unsubscribe: false,
        },
      })
      await subscribe({
        variables: {
          token,
          topic: "language-ro",
          unsubscribe: false,
        },
      })
    }
  }, [tag])

  const renderBtn = (permission) => {
    if (permission === "default") {
      return (
        <button
          className="notifications-button"
          onClick={onSubscriptionBtnClick}
        >
          <FontAwesome name="bell-o" className="mr-2" />
          {intl.formatMessage({ id: 'agency.subscribe' })}
        </button>
      )
    } else if (permission === "subscribed") {
      return (
        <button className="notifications-button notifications-button-success">
          <FontAwesome name="bell-o" className="mr-2" />
          {intl.formatMessage({ id: 'agency.subscribed' })}
        </button>
      )
    } else if (permission === "denied") {
      return (
        <button className="notifications-button notifications-button-warning">
          <FontAwesome name="bell-slash-o" className="mr-2" />
          {intl.formatMessage({ id: 'agency.unsubscribed' })}
        </button>
      )
    } else {
      console.warn("Unrecognised permission", permission)
    }
  }

  return <div className="mb-4">{renderBtn(permission)}</div>

  /**
   * Find current subscription state
   **/
}

export default NotificationsForm

const FCM_SUBSCRIBE = gql`
  mutation subscribeTopic(
    $token: String!
    $topic: String!
    $unsubscribe: Boolean
  ) {
    subscribeTopic(token: $token, topic: $topic, unsubscribe: $unsubscribe) {
      topics
    }
  }
`

const FCM_TOPICS = gql`
  query getTopics($token: String!) {
    getTopics(token: $token) {
      topics
    }
  }
`
